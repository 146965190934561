import { Link } from 'gatsby';
import { getEventDateRange } from '../util';
import React from 'react';
import { Event } from './Event';
import { navigateToEventSectionDetails } from '../helpers/eventSections.helper';

interface OtherUpcomingEventProps {
  upcomingEvent: Event;
}

const OtherUpcomingEvent: React.FC<OtherUpcomingEventProps> = props => {
  return (
    <Link to={navigateToEventSectionDetails(props.upcomingEvent)}>
      <div className="bg-white rounded-lg shadow mt-4 p-4 cursor-pointer flex">
        <div className="ml-4">
          <p className="text-sm text-gray-700 font-semibold sans-serif">
            {props.upcomingEvent.title}
          </p>
          <p className="text-xs text-gray-500 uppercase mt-1">
            {getEventDateRange(
              new Date(props.upcomingEvent.startDate),
              new Date(props.upcomingEvent.endDate)
            )}
          </p>
        </div>
      </div>
    </Link>
  );
};

interface OtherUpcomingEventsProps {
  upcomingEvents: Event[];
}

export const OtherUpcomingEvents: React.FC<OtherUpcomingEventsProps> = props => {
  return (
    <>
      {props.upcomingEvents.map((event: Event, i: number) => {
        return <OtherUpcomingEvent key={i} upcomingEvent={event} />;
      })}
    </>
  );
};
