import {
  faCalendar,
  faGraduationCap,
  faMapMarkedAlt,
  faMoneyBill
} from '@fortawesome/free-solid-svg-icons';
import { getEventDateRange } from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface EventDetailsProps {
  details: {
    startDate: Date;
    endDate: Date;
    grades: string;
    price: string;
    location: string;
  };
}
const EventDetails = (props: EventDetailsProps) => {
  return (
    <div className="bg-primary text-white p-4 rounded flex justify-center">
      <p className="mb-1 mr-6">
        <FontAwesomeIcon icon={faCalendar} className="mr-2" />
        <span>
          {getEventDateRange(props.details.startDate, props.details.endDate)}
        </span>
      </p>
      {props.details.grades && (
        <p className="mb-1 mr-6">
          <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
          {props.details.grades.toUpperCase()}
        </p>
      )}
      <p className="mb-1 mr-6">
        {props.details.price !== 'FREE' && (
          <FontAwesomeIcon icon={faMoneyBill} className="mr-2" />
        )}
        {props.details.price}
      </p>
      <p className="">
        <FontAwesomeIcon icon={faMapMarkedAlt} className="mr-2" />
        {props.details.location}
      </p>
    </div>
  );
};

export default EventDetails;
