import React, { useRef, useState } from 'react';
import { applyToEvent } from './members/api';
import Button from './common/Button';
import ModalWrapper from './common/ModalWrapper';
import { navigate } from 'gatsby';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  policy: string;
  eventId: string;
}

enum APPLY_TO_EVENT_STATES {
  IDLE,
  PROCESSING,
  ERROR
}

const EventApplicationPoliciesModal = ({
  isOpen,
  onClose,
  policy,
  eventId
}: Props) => {
  const cancelButtonRef = useRef(null);

  const [applyToEventState, setApplyToEventState] = useState(
    APPLY_TO_EVENT_STATES.IDLE
  );
  const [applyToEventError, setApplyToEventError] = useState('');

  const applyToEventMutation = async () => {
    setApplyToEventState(APPLY_TO_EVENT_STATES.PROCESSING);
    try {
      const result = await applyToEvent(eventId);

      if (result.state === 'ERROR') {
        setApplyToEventState(APPLY_TO_EVENT_STATES.ERROR);
        setApplyToEventError(result.message);
      } else {
        navigate(
          `/members/events/applications/${result.data.id}/manage/general-information`
        );
        setApplyToEventState(APPLY_TO_EVENT_STATES.IDLE);
      }
    } catch (err) {
      setApplyToEventState(APPLY_TO_EVENT_STATES.ERROR);
    }
  };

  return (
    <ModalWrapper onClose={onClose} isOpen={isOpen}>
      {!!policy ? (
        <div
          className="text-gray-500"
          dangerouslySetInnerHTML={{ __html: policy }}
        ></div>
      ) : (
        <div className="flex justify-center py-4">
          There is no policy for this event
        </div>
      )}
      <div className="mt-5 flex justify-center">
        <div>
          <Button
            type="button"
            onClick={applyToEventMutation}
            text="Accept and Continue"
            isLoading={applyToEventState === APPLY_TO_EVENT_STATES.PROCESSING}
          />
        </div>
        <div className="ml-4 my-auto">
          <button
            type="button"
            className="sans-serif text-red-500 px-2 py-1 focus:outline-none"
            onClick={onClose}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </div>
      {applyToEventState === APPLY_TO_EVENT_STATES.ERROR ? (
        <p className="text-red-500 sans-serif mt-4 text-center">
          Error: {applyToEventError}
        </p>
      ) : null}
    </ModalWrapper>
  );
};

export default EventApplicationPoliciesModal;
